import React, {useState, useEffect} from 'react'
import {useNavigate } from 'react-router-dom'
import {NavLink} from 'react-router-dom'
import {Navbar} from 'react-bootstrap'
import {Get} from './Api'

export const Navigation = ({onRole, layout, route}) =>{
  const navigate = useNavigate()
    const [role, Role] = useState(1) // I m connected
    const [loaded, Load] = useState(false)
    const [expanded, Expanded] = useState(false)
    const [items , Items] = useState([])

    useEffect(() => {
      if(loaded){
        //console.log(items); alert('Navigation '+items[0].RoleId)
        if(items.length>0){
          Role(items[0].RoleId)
          onRole && onRole(items[0].RoleId)
        }
      }
      else {
        Get(`Setting/Authorization`, Items, Load)
      }
   }, [loaded])

  const Logout = (e) => {
      localStorage.removeItem('Akademy')

      navigate(`/`,{replace:true})
      window.location.reload()
  }

    const navItems = (
        <Navbar.Collapse id="basic-navbar-nav" onClick={()=>Expanded(!expanded)}>
          <NavLink className="navbar-custom" to="/" onClick={Logout} >
                <i className="fa fa-sign-out"> خروج</i>
          </NavLink>

          <NavLink className="navbar-custom" to="/course">الدورات</NavLink>
          {route!=='/' && <NavLink className="navbar-custom" to="/">الرئيسية</NavLink>}
        
          {role>1 ?
          <>
          <NavLink className="navbar-custom" to="/meditating" >تدبُّر</NavLink> 
          <NavLink className="navbar-custom" to="/repetition" >تكرار</NavLink> 
          <NavLink className="navbar-custom" to="/memorizing" >إحفظ</NavLink> 
          <NavLink className="navbar-custom" to="/reading" >ٱقۡرَأۡ</NavLink>
          <NavLink className="navbar-custom" to="/performing" >تنفيذ</NavLink>
          <NavLink className="navbar-custom" to="/booking" >حجز المرور</NavLink>
          </>
          :null}

          {role>2 ? 
          <>
          <NavLink className="navbar-custom" to="/evaluation" >التقييم</NavLink>  
          <NavLink className="navbar-custom" to="/team" >الفرق</NavLink>
          <NavLink className="navbar-custom" to="/subject" >المواضيع</NavLink>
          <NavLink className="navbar-custom" to="/planning" >تخطيط</NavLink>    
          </>
          :null}

          {role>6 ?
          <>
          <NavLink className="navbar-custom" to="/setting" >إعدادات</NavLink>
          <NavLink className="navbar-custom" to="/person"  >الأعضاء</NavLink>
          <NavLink className="navbar-custom" to="/managing" >إدارة</NavLink>
          <NavLink className="navbar-custom" to="/registering" >مُسجَّلون</NavLink>
          </>
          :null}

        </Navbar.Collapse>

    ) 


  return (
    
        layout ? 
        <Navbar>
          {navItems}
        </Navbar>
        :
      
        <Navbar className="navbar-down" expand='lg' expanded={expanded}>
          <Navbar.Toggle className="navbar-dark bg-inverse" onClick={()=>Expanded(!expanded)} />
          {navItems}
        </Navbar> 
    )
}


const Dropdown = ({ submenus }) => {
    return (
      <ul className="dropdown">
        {submenus.map((submenu, index) => (
          <li key={index} className="menu-items">
            <a href={submenu.url}>{submenu.title}</a>
          </li>
        ))}
      </ul>
)}
