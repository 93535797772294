import React,{Component} from 'react';
import { Form } from 'react-bootstrap';

export class CountryControl extends Component{
    constructor(props){
        super(props)
        this.state = {countries:[], loading:false, country:this.props.value??1}
        this.apiUrl=`${this.props.apiUrl}/Country`

        this.label=this.props.label ?? 'Country'
        this.readOnly = this.props.readOnly ?? false

        this.onCountry = this.props.onChange ?? this.onChange
    }
 

    componentDidMount(){
        this.setState({loading:true})
        //alert(this.apiUrl)
        fetch(this.apiUrl)
        .then(response => response.json())
        .then(data => {
            this.setState({countries:data, loading:false})
        } )
        .catch(function (error) {
            console.log(error)
          })
    }

    componentWillUnmount() { 
        this.setState({countries:[]})        
    }

    onChange = (e) => {
        //alert(e.target.value)
        this.setState({country: e.target.value})
        this.props.onChange && this.props.onChange(e)
    }

    render() {
 
        return (
            this.state.loading ? 
            <h5>Countries are loading...</h5> 
            :
            <Form.Group className='form-row'>
                
            <Form.Control className='col-sm-12 text-center' onChange={this.onCountry} as="select" 
                name="CountryId" defaultValue={this.props.itemId ?? 1} 
                style={{marginTop:'1rem'}}
                readOnly={this.readOnly} >
                {this.state.countries.map(country => 
                    <option key={country.Id} value={country.Id}>{(country.Name+" "+country.Phone)}</option>
                )}
            </Form.Control>
         </Form.Group>             
        ) 
    }
}
