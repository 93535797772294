import React, { useState, useEffect, useLayoutEffect } from 'react'
import './PostIt.css'
import {ColorsControl, FontsControl} from '../Shared/ContextMenuControl'
import {meComponent, Fetch} from '../Shared/meGlobal'
import {Get} from '../Api'

const PostItLayer = ({entity, element, role, search='', onEtat}) => {

    const [loaded, Load] = useState(false)
    const [curating, Curate] = useState(false) // select, organize, and look after the items in (a collection or exhibition).
    const [items , Items] = useState([])
    const curator = new meComponent("PostIt")

    var layer = document.getElementById(`PostItLayer${element}`)
    const contextMenu = document.querySelector('.context-menu')
    //alert(layer.id)
    
    useEffect(() => {
        let request = `PostIt/${element}` //; alert(request)
        Get(request, Items, Load)

        //Items(items => [...items, {Id:1, Content:'Good', Coord:'50,50,20.75,10.25'}])
    }, [element, loaded])

    useLayoutEffect(()=>{
        items.forEach((item) => {
            //console.log(item); alert(item.Id)
            const element = document.getElementById(curator.category+item.Id)
            if(element) {
                curator.SetStyle(element, item.Coord)

                const content = document.getElementById('Content'+item.Id)

                curator.SetColor(content, item)
                curator.SetFont(content, item)

                /*
                content.style.backgroundColor =  `rgba(${item.Back})` 
                content.style.color =  `rgba(${item.Text})` 
                if(item.Border?.length>0)
                    content.style.borderColor =  `rgba(${item.Border})`

                content.style.fontFamily = item.Family
                content.style.fontSize = `${item.Size}rem` 

                if(item.Weight?.length>0)
                    content.style.fontWeight = item.Weight
                
                if(item.Style?.length>0)
                    content.style.fontStyle = item.Style 
                */

                layer.appendChild(element)
                //alert(element.style.fontSize)
            }
        })
        curator.selectorAll()
        Curate(false)

    },[items])

    const OnCurate=(event) => {
        //alert(`curating ${curating}`)
        Curate(!curating)

        if(!curating) {
            event.target.title="curating"
            event.target.style.border = '1px solid red'
        }
        else
        {
            event.target.title="toCurate"
            event.target.style.border = 'none'
        }
    }

    useEffect(() => {
        if(layer) {
            if(curating) {
                layer.style.backgroundColor='rgba(200, 200, 200, 0.5)'
                layer.style.height = '100%'
            }
            else {
                layer.style.backgroundColor='transparent'
                layer.style.height = '1.5rem'
    
                if(curator.category==='PostIt' || curator.category==='CorpIt') {
                    for (let i = 0; i < curator.items.length; i++) {
                        curator.items[i].style.border = 'none'
                    }
                    if(curator.category==='PostIt') {
                        layer.querySelectorAll('textarea').forEach(function(item) {
                            item.readOnly=true
                            item.style.cursor = 'pointer'
                            item.style.border = 'none'
                            item.parentElement.style.border = 'none'
                        })
                    }
                }
            }
        }
    }, [curating])

    const OnSelect = (label, item) => {
        if(item) {
            var content = document.getElementById(contextMenu.title)
            if(content) {
                let id = content.id.replace('Content','') // alert(id)
                const data = {action:label, id:id, color:5, font:3}

                if(label==='Colors') {
                    curator.SetColor(content, item)   
                    /*                 
                    content.style.backgroundColor = `rgba(${item.Back})`
                    content.style.color = `rgba(${item.Font})`
                    //alert(content.style.color)
                    */
                    data.color=item.Id
                }
                else if(label==='Fonts') {
                    curator.SetFont(content, item)
                    /*
                    content.style.fontSize = `${item.Size}rem`
                    //alert(content.style.fontSize)
                    if(item.Family.length>0)
                        content.style.fontFamily = item.Family
                    if(item.Weight.length>0)
                        content.style.fontWeight = item.Weight
                    if(item.Style.length>0)
                        content.style.fontStyle = item.Style
                    */
                    data.font=item.Id
                }

                Fetch(curator.track, data, content, 'PUT')    
            }
            contextMenu.style.display = 'none'
        }
        else {
            contextMenu.querySelectorAll('.sub-menu').forEach(function(item) {
                if(item.title===label) {
                    item.style.display = (item.style.display==='none')?'block':'none'
                }
                else
                    item.style.display = 'none'
            })
        }
    }

    const ActionMenu = (event, mode=1) => {
        event.preventDefault() 
        if(curating && contextMenu) {
            //alert(`event ${event.clientX},${event.clientY}`)       //onEtat(`event ${event.clientX},${event.clientY}`)
            contextMenu.style.top = (event.clientY) + 'px'
            contextMenu.style.left = event.clientX + 'px'
            contextMenu.style.display = 'block'

            contextMenu.title = event.target.id

            if(mode===0) {
                if(contextMenu.style.display === 'block')
                    contextMenu.style.display = 'none'
            }
        }

        if(event.detail===2){
            //alert(`${role} - ${event.detail} - ${event.target.id} `)
            if(curating && role>1) {
                event.target.readOnly = !event.target.readOnly
                if(event.target.readOnly) {
                    event.target.style.cursor = 'pointer'
                    event.target.style.border = 'none'
                }
                else {
                    event.target.style.cursor = 'text'
                    event.target.style.border = '1px solid lightblue'
                }
            }
            event.stopPropagation()    
        }
    }

    const OnAdd = (event = {clientX:600, clientY:100}) => {
        if(curating) {
            if (event.shiftKey) {
                let coord = curator.toCoord(event.clientX,event.clientY,200,30)

                const data = {action:'Add', content:'New', element:element, coord:coord}
                //console.log(data);alert(`${data.action} ${data.coord}`)            
                
                Fetch(curator.track, data, null, 'PUT')
                
                Load(false)
            }
        }
    }

    const OnMouse = (event, type) => {
        if(curating) {
            if(type==='Down') {
                curator.mouseDown(event)
            }
            else if(type==='Move') {
                curator.mouseMove(event)
            }
            else if(type==='Up') {
                curator.mouseUp(event)
            }
        }
    }

    const filtered = items.filter(
        (item)=>{
            return (item.Title+item.Content).indexOf(search)!==-1;
        }   
    )

return (

<div id={"PostItLayer"+element} class="postit-body" 
    onDoubleClick={e => OnAdd(e)}
    onMouseDown={e=>OnMouse(e,'Down')} 
    onMouseMove={e=>OnMouse(e,'Move')} 
    onMouseUp={e=>OnMouse(e,'Up')}
>
    <img class="postit-icon" src="icons/edit.png" alt='PostIt' title='PostIt' onClick={e=>OnCurate(e)} />
    
    <ul class="context-menu">
        <ColorsControl onSelect={OnSelect} />
        <FontsControl  onSelect={OnSelect} />
    </ul>

    { filtered?.length>0 && filtered.map((item, index) => (  
        <PostIt category={curator.category} item={item} curator={curator} role={role} onContextMenu={ActionMenu} />
    ))}

</div>
    )
}


const PostIt = ({category, item, curator, role, onContextMenu}) => {
 
    const OnContent = (event) => {
        let id = event.target.id.replace("View", "Content")
        const content = document.getElementById(id)

        if(event.target.title==="Content") {
            event.target.title="NoContent"
            content.style.color=content.style.color.replace("0.7","0.03")
        }
        else {
            event.target.title="Content"   
            content.style.color=content.style.color.replace("0.03","0.7")  
        }
    }    
    
    const OnClose = (event) => { 
        if(event.target?.title==="Close") {
            event.target.title="Closed"
            event.target.parentElement.style.visibility='hidden'
        }
    }
    
    const OnUpdate = (event) => {
        const content = event.target
        let keycode = (event.keyCode ? event.keyCode : event.which)
        //alert(`update ${keycode} - ${event.shiftKey}`)
        if (keycode === 13) {
            if (event.shiftKey) {
    
            }
            else {
                //alert(`update ${event?.target.id}...`)
                if(role>2) {
                    let id = content.id.replace('Content','')
                    if (id > 0) {

                        let data = {action:'Update', element:id, content: content.value.slice(0, -1), title:''}           
                        Fetch(curator.track, data, content, 'PUT')
                    }
                    OnRead(content)   
                }
            }
            event.stopPropagation()
        }
        else if (keycode === 27) {
            content.value = content.defaultValue
            OnRead(content)
        }		
    }

    const OnRead = (content, read=true) => {
        //alert(`update ${content.id}...`)
        content.readOnly = read
        if(read) {
            content.style.cursor = 'pointer'
            content.style.border = 'none'   
            content.parentElement.style.border = 'none' 
        }
        else {
            content.style.cursor = 'text'
        }
    }

// element is the html object  (like this)
// item is the database object    
    
return (
<div class="postit-item" id={category+item.Id}>
    <textarea class="postit-body" id={"Content"+item.Id} name="Content" rows="1" 
        defaultValue={item.Content}
        title={`${item.Name} - ${item.Date} - (${item.Id}) ${item.Title}`}
        onClick={e => onContextMenu(e,0)}
        onKeyUp={e => OnUpdate(e)}  
        onContextMenu={e => onContextMenu(e)} 
        readOnly={true}
        style={{cursor:'pointer'}}
    >
    </textarea>
    
    <div class="postit-icon" id={`Close${item.Id}`} title="Close" onClick={(e) => OnClose(e)}>
    </div>
    <div class="postit-icon postit-content" id={`View${item.Id}`} title="Content" onClick={(e) => OnContent(e)}>
    </div>
</div>
    )
}

export  {PostItLayer}


