import React, { useState, useEffect, useLayoutEffect } from 'react'
import './Managing.css'
import {PerformingDetail} from '../Performing/PerformingDetail'
import {PlanningDetail} from '../Planning/PlanningDetail'
import {meSplitter} from '../Shared/meGlobal'

export const Managing = ({global, layout, search}) => {
        const activity=5
        const [planning, Plan] = useState(0)
        const [perform, Perform] = useState(0)
        const [reported, Reported] = useState(6)

        const main = new meSplitter("Side", "MainContainer", layout)

        useLayoutEffect (()=>{  
                main.selectorAll() 
                //alert(main.items.length)      
            },[])

        const onPlan = (id) => {
                Plan(id)
        }

        const onPerform = (id) => {
                Perform(id)
        }

        useEffect(() => {
                sessionStorage.setItem("routeCurrent","/managing")
                //alert('useEffect '+itemId)
        }, [planning])    


return (

global.connected() ?

<div className="Sides" id="MainContainer"
onMouseDown={(e)=>{main.mouseDown(e)}} 
onMouseMove={(e)=>{main.mouseMove(e)}} 
onMouseUp={(e)=>{main.mouseUp(e)}}
>
    <div className={layout ?"side vside":"side hside"} id="PerformingSideDetail" 
        style={layout ?{width:'50%'}:{height: '50%'}}>
        <PerformingDetail global={global} planning={planning} onPerform={onPerform} />
    </div>     

        <div className={layout ?"side vside":"side hside"} id="PerformingSidePlanning" 
        style={layout ?{width:'50%'}:{height: '50%'}}>
        <PlanningDetail global={global} onPlan={onPlan} mode={9} />
    </div>
   
</div>
: <h5>{global.message}</h5> 
)
}
