import React, {useState, useEffect} from 'react';
import {Row, Col, Navbar} from 'react-bootstrap';
import {NavLink} from 'react-router-dom'; 
import {Navigation} from './Navigation';
import SignIn from './Sign/SignIn'
import SearchControl from './Shared/SearchControl'

const Header = ({connected, onSearch, onRole, layout, route}) => {
     
    const [showing, Showing] = useState(false)

    useEffect(() => {
        //alert('Header connected '+connected)
    }, [showing])

      
    const Login = (e) => {
        Showing(true)
    }

    return (
        
        <div className="Header" id="Header">
            <Row className="navbar navbar-custom" expand="lg">
            <Col sm={6}>
            { connected ?

<Navigation onRole={onRole} layout={layout} route={route} />
:
                <Navbar >
                    <NavLink className="navbar-custom" to="/signup" >
                        <i className="fa fa-edit"> التسجيل</i></NavLink>  

                    <NavLink className="navbar-custom" style={{marginLeft:"5rem"}} onClick={Login} >
                        <i className="fa fa-sign-in"> الدخول</i></NavLink>  
                </Navbar>
    }

            </Col>             
            <Col sm={3} style={{textAlign:'center'}} >
            <SearchControl onSearch={onSearch} />
            </Col>

            <Col sm={3} className="top-right">
                <a href="/">
                    <img className="Logo" src="logo.png" alt="الرئيسية" />
                </a>
            </Col>            
            </Row>

{ <SignIn 
    show={showing}
    onHide={()=>Showing(false)}
/> }
                  
        </div>
        
        
    )
}

export default Header;