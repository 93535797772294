import React, { useState, useEffect, useLayoutEffect } from 'react'
import './Managing.css'
import '../meTree/meTree.css'
import Activities from '../Shared/Activities'
import {MemberGrid} from '../Member/MemberGrid'
import {meSplitter} from '../Shared/meGlobal'
import {ToNotify} from '../Shared/ViewControl'
import Api from '../Api'

export const Persons = ({global, layout, search}) => {
 
        const activity=5
        const [team, Team] = useState(0)
        const [note, Note] = useState(0)
        const [reported, Reported] = useState(0)
        const [message, Message] = useState('')

        const main = new meSplitter("Side", "MainContainer", layout)

        useLayoutEffect (()=>{  // like useEffect but synchronously after all DOM
                main.selectorAll()  
            },[])
        
        const onNote = (id) => {
            Note(id)
        }

        const onMember = (id) => {
                //alert(id)
        }

        useEffect(() => {
                //alert('useEffect '+itemId)
        }, [team])   

        const onMouseUp = (event) => {
                main.mouseUp(event, Api)
        }
        

return (

global.connected() ?

<>

<div className="Sides" id={`MainContainer`}
            onMouseDown={(e)=>{main.mouseDown(e)}} 
            onMouseMove={(e)=>{main.mouseMove(e)}} 
            onMouseUp={onMouseUp}
        >

{ layout ?
<>
<div className="side vside" id={`SideActivities`} style={{width: '30%'}}>
        <Activities activity={activity} element={1002} onElement={onNote} reported={reported} search={search} />
</div>

<div className="side vside" id={`SideMembers`} style={{width: '70%'}}>
        <MemberGrid global={global} onMember={onMember} Message={Message} search={search} />
</div>
</>
:
<>
<div className="side hside" id="SideMembers" style={{height: '50%'}}>
        <MemberGrid global={global} onMember={onMember} Message={Message} search={search} />
</div>

<div className="side hside" id={`SideActivities`} style={{width: '30%'}}>
        <Activities activity={activity} element={1002} onElement={onNote} reported={reported} search={search} />
</div>
</>
}
</div>

<ToNotify message={message} onClose={()=>Message('')} />

</>
: <h5>Not Connected</h5> 
)
}

