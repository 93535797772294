import axios from 'axios';

const Api = axios.create({

  // .Net 
  baseURL: `https://akademy.digitalservices.ma`, // Prod
  //baseURL: `https://api.raisdigital.com`,  // Beta
  //baseURL: 'https://dev-api.digitalservices.ma', // Dev 
  //baseURL: `https://localhost:44371`,  // Local

  // Node
  nodeURL: `https://api.akademy.ma`,
  //nodeURL: `https://api.echchatt.net`, // Bete
  //baseURL: `http://localhost:2021`

  headers : {
    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('Akademy'))?.token}`,       
    'Content-Type': 'application/json',
    //'Accept': 'application/json',
    //'Access-Control-Allow-Origin': '*',
    //'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept'
  },

  domain: 'Tajwyd'
})


  // MeR 240514
const Exists =  (type,value, Message=null) => {
    return new Promise(function (resolve, reject) {
      let request= `Exists/${type}/${value}`
      Api.get(request)
      .then(response => {
          //console.log('Api.Exists', request, response); alert(response?.status)
          resolve(response?.status)
      })
      .catch(error => {
          reject(error)
        //console.error(error)
      })
    })
  }


async function Fetch(request, item=null, crud=2, Load=null, Message=null, Items=null, Status=null, success=null) {

  const methods="1234"
  if(!methods.includes(crud)) 
    throw new Error(`Fetch method ${crud} invalid ?`) 

  const method = (crud===1 ? 'POST' : (crud===3 ? 'PUT' : (crud === 4 ? 'DELETE' : 'GET')))

  //console.log('Fetch', request, crud, method, Api.defaults.headers, item);  alert(Api.defaults.baseURL+' '+request+' '+crud+' '+method+' '+item.Id)
  if(!request?.startsWith('https'))    
      request = Api.defaults.baseURL+"/"+request

  let response = await fetch(request,{
      method: method,
      headers: Api.defaults.headers,
      body: item && JSON.stringify(item)
  })

  if(response.ok) {
    if(crud!==2) {
      Status && Status(response.status)
      Message && Message(success ?? `Api.Fetch ${response.status} !`)
      Load && Load(false)
      //console.log(response); alert(response.status)
    }
    else {
      let data = await response.json()
      //console.log('Fetch ', request, data)
      Items && Items(data)  // 2 hours :) 
      Load && Load(true)
    }
  }
  else {
    Message && Message(`Api.Fetch ${response.status} ?`)
    //console.error(response)
  }
}

async function Post(controller, item, Message=null, Load=null) {

  let response = await Api.post(controller, item)
  //console.log('Api.Post', controller, item, response?.data) ; alert(response.status)  
  if([201,202,302].includes(response?.status)) {   
    //alert(response?.data)
    Message ?? Message(response?.data) 
    Load ?? Load(false)
  }
  else {
    let message = `? Api.Post ${response?.data} ?`
    Message ?? Message(message)    //console.error(response)
  }
}

const Get = (request, Items, Load=null, Message=null, Status=null) => {
  //alert(request)
  Items([])
  Api.get(request)
  .then(response => {
    Items(response.data)
    Status && Status(response?.status)    
    
    //console.log('Api.Get', request, response, response.data); alert(request+' then')
    Load && Load(true)
  })
  .catch(error => {
      //console.log('Api.Get', request, error, error.response, error.response.data); alert(request+' catch '+error.Message)
      Message && Message(error?.response?.data?.Message ?? error.message)
      Status && Status(error?.status)    
      //console.error(error)
  })
}



const Cookie = (key) => {
  let name = (key + "=")
  let cookies = decodeURIComponent(document.cookie)
  //console.log('cookies',key,cookies)

  let ca = cookies.split(';')
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
}

/*
const cacheData = {};

const Cache = async (request) => {
  // if cache doesn't contain data
  if (!cacheData[request]) {
    // load data and add it to cache
    const { data } = await axios.get(request);
    cacheData[request] = data
  }
  // cached data
  return cacheData[request];
}
*/

export default Api
export {Fetch, Get, Post, Cookie, Exists}

