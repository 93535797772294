import React,{Component} from 'react'
import SignIn from './Sign/SignIn'
import {Services} from './Service/Services'

export class Home extends Component{

    constructor(props){
        super(props)
        this.state = {loading:false, showing:false}

        sessionStorage.setItem("routeCurrent","/")
    }

    Login = (e) => {
      this.setState({showing:true});
  }

    render() {

        return (

            <div className="mt-11 d-flex justify-content-center">

<Services connected={this.props.connected} onLogin={this.Login} />

{ this.state.showing && <SignIn 
    show={this.state.showing}
    onHide={()=>this.setState({showing:false})}
/> }

            </div>
        )
    }
}