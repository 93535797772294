import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import {useNavigate} from 'react-router-dom'

import {Button} from 'react-bootstrap'
import '../Managing/Managing.css'

import {setting} from '../Config/ar'
import {Wajhs, WajhModal} from './Wajhs'
import {Delay} from '../Timer/Timers'
import {getFile, getPage} from '../Shared/Akademy'
import {Get} from '../Api'
import {meSplitter} from '../Shared/meGlobal'
import TreeView from "../meTree/TreeView"

const Repetition = ({course, layout, onEtat, search}) => {
    const navigate = useNavigate()
    const onCourse = useCallback(() => navigate(`/course`,{replace:true}), [navigate])
    const [courses, Courses]= useState([])

    const entity='Repetition'
    const [selected, Select] = useState([])
    const [note, Note] = useState(0)
    const [safha, Safha] = useState(0)
    const [soora, Soora] = useState(0)
    const [play, Play] = useState(false)
    const [repeat, Repeat] = useState({i:0, n:2})
    const [speed, Speed] = useState(4)
    const [tree, Tree] = useState([course,0,0,0])

    const [tilaawa, Tilaawa] = useState("")
    const [wajh, Wajh] = useState("")

    const istyle = {display: 'flex', justifyContent: 'center'}
    const tstyle = {position:'absolute', width: '10%', height: '5%', fontWeight:'bold', color:'darkred'}

    var audio = document.getElementById("tilaawa")

    const splitter = new meSplitter(`${entity}Side`,`${entity}Container`,layout)
    useLayoutEffect (()=>{  
        //alert(layout)
        splitter.selectorAll() 
    })

    useEffect(() => {
        Get("Setting/CoursesAccepted", Courses)
    }, [course])    
    
    useEffect(()=>{
        if(tree[0]<2) {
            onCourse()
        }
    
        Soora(tree[2])
        //alert(`tree ${tree.length} - ${tree[0]} & ${tree[1]} & ${tree[2]}`)
        
    },[tree])

        useEffect(()=>{
            if(audio) {
                audio.playbackRate=speed
                //alert(speed)
            }
        },[speed])
    
        const onPage = (item) => {
            if(play) {
                onPlay()
            }
            
            let pages = selected
            const i = selected.indexOf(item.Safha)
            if (i > -1) { 
                pages.splice(i, 1)
            }
            else {
                pages.push(item.Safha)
            }
            pages = pages.sort()
            Select(pages)

            if(pages.length>0) {
                Safha(pages[0])
                if(item.Safha===pages[0]) {
                    Soora(item.Soora)
                    //alert(item.Soora)
                }
            }

            let etat=`Wajhs ${pages.length}` //; alert(etat)
            onEtat && onEtat(etat)

            //console.log('selected',selected) //; alert(safha)
        }

        // MeR 240924
        const onNode = (e, levels) => {
            //alert(`levels ${levels.length} - ${levels[0]} & ${levels[1]} & ${levels[2]}`)
            Tree([...levels])
            //Tree({...tree, course:levels[0]})
            //Node(levels[0])
        }

    
        const onSpeed = e => {
            e.preventDefault()
            let value=parseInt(e.target.value)
            Speed(value)        
        }
    
        const onRepeat = e => {
            e.preventDefault()
            Repeat({i:0, n:parseInt(e.target.value)})
        }    

        const onNote = (id) => {
            Note(id)
        }

        useEffect(()=>{
            if(play &&  tilaawa?.endsWith('.mp3')) {
                audio.playbackRate=speed                
                audio.play()   
            }     
        },[tilaawa])

        useEffect(()=>{
            if(play && soora>0 && safha>0 && repeat.i<repeat.n) {
                
                const file = getFile(soora, safha) //; alert(file)

                Tilaawa(file)
                Wajh(getPage(safha))
                /*
                if(file===tilaawa) 
                {  // important for repeat ! 230122 
                    audio.playbackRate=speed
                    audio.play() // Commented at 240218 but this solve an issue detected at 240314
                }
                    */
            }
        },[soora, safha, repeat])

        async function onEnded() {
            //console.log('onEnded Repeat',repeat,part,items); alert(`onEnded ${play} ${repeat.i} ${repeat.n} ${part}`)
        
            if(play && repeat.i<repeat.n && selected.length>0) 
            {
                const s = selected.indexOf(safha)
                if(s+1===selected.length) { // Added  at 240320 great caught  
                    //alert(`delay ${repeat.i} - ${repeat.n}`)
                    await Delay(Math.trunc(6000/speed))    
                }
                else {
                    await Delay(Math.trunc(3000/speed))
                    Safha(selected[s+1])
                    return
                }
                
                if(selected.length===1)
                    Tilaawa('')
                    Wajh('')

                Repeat({...repeat, i:repeat.i+1})
                
                if(repeat.i+1===repeat.n) {
                    //alert(`delay ${repeat.i} - ${repeat.n}`)
        
                    onPlay()
                }
                else {
                    Safha(selected[0])
                    //alert(`onEnded 2 ${p} ${items[p].Locked} ${items.length} ${part}`)

                }
            }
        }         
        
        const onPlay = () => {
            if(!play) {
                Repeat({...repeat, i:0})
                audio.playbackRate=speed
                audio.style.visibility='visible'
                audio.play()  
                Play(true)  
            }
            else {
                audio.pause()
                audio.style.visibility='hidden'
                Play(false)
            }
        }
        

return (
    courses.length===0 ? <div className="Loading Error">{setting.course.unaccepted}</div> :

    <div className="Sides" id={`${entity}Container`} 
        onMouseDown={(e)=>{splitter.mouseDown(e)}} 
        onMouseMove={(e)=>{splitter.mouseMove(e)}} 
        onMouseUp={(e)=>{splitter.mouseUp(e)}}
        >
        <div className={layout ?"side vside":"side hside"} id={`${entity}SideWajhs`} style={layout ? {width:'70%'}:{width:'100%'}}>
            <div className='parts' style={{height:'50px', paddingTop:'0.5rem'}}>
            <audio id="tilaawa" controls="controls" autobuffer="autobuffer"
onEnded={onEnded}
style={{...tstyle, left:'25%', width:'50%', height:'2rem', visibility:'hidden', opacity:'0.8'}} 
src={tilaawa} />

    <div style={{ ...tstyle, left:'10%', height: '2rem' }}>
                <p>{play?repeat.i+1:""} </p>
    </div>
    <Button className="mr-2" variant={play?"danger":"success"}  
            style={{ ...tstyle, left:'40%', width: '20%', height: '2rem', opacity:'0.6' }}
                onClick={onPlay}
                ><i className={play?"fa fa-pause":"fa fa-play"} style={istyle}></i>
    </Button>
    <select id="repeats" title='التكرارات' style={{ ...tstyle, left:'75%', width:'3rem', height: '2rem', opacity:'0.6', zIndex:'60'}} 
        onChange={onRepeat} 
        defaultValue={repeat.n}
        >
        <option value='1'>1</option><option value='2'>2</option><option value='3'>3</option><option value='5'>5</option><option value='7'>7</option>
    </select>

    <select id="speeds" title='السرعة' style={{ ...tstyle, left:'85%', width:'3rem', height: '2rem', opacity:'0.6'}} 
        onChange={onSpeed}
        defaultValue={speed}
        >
        <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option>
    </select>

            </div>
                <Wajhs course={tree[0]} joze={tree[1]} soora={tree[2]} safha={safha} onPage={onPage} selected={selected} />
        </div>
        
        <div className={layout ?"side vside":"side hside"} id={`${entity}SideTree`} style={layout ? {width: '30%', bottom:0, verticalAlign:'bottom'}:{width: 0, bottom:0, verticalAlign:'bottom'}}>
                <TreeView onChange={onNode} page={safha}  course={course} levels={3} />
        </div>

{play && wajh.endsWith(".jpg") ? 
    <WajhModal wajh={wajh} show={play}  onHide={()=>{Wajh('')}} />
: null}

    </div>
)
}

export default Repetition
